import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetPasswordRequest } from "plugins/redux/reducers/AuthReducer";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<any>({
    mode: "onBlur",
  });

  const onSubmit = async (data: Object) => {
    const response = await dispatch(resetPasswordRequest(data) as any);
    if (response.payload.success) {
      setSuccessMessage(response.payload.success)
      setEmailSent(true);
    }
    setErrorMessage(response.payload);

  };
  return (
    <div className="row m-0">
      <div className="col-4">
        <div className="left-logo">
          <img className="w-25 p-6" src="/images/BevBridgeRFPLogo.png" />
        </div>
        <div className="min-100vh d-flex flex-column  justify-content-center">
          <div className="welcome-message ">
            <h1 className="font-weight-bold text-uppercase mb-10 fw-bold">Password Reset</h1>
          </div>
          <div className="login-image align-self-center">
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 d-flex align-items-center justify-content-center min-100vh bg-custom-light">
        {emailSent ? (
          <div><h1 className="mb-10 fw-bold">Email sent successfully</h1>
            <p className="mb-10 fw-bold">{successMessage}</p>

          </div>
        ) : (<div>
          <h1 className="mb-10 fw-bold">Password Reset</h1>
          <p className="mb-10 fw-bold">Please enter the email address associated with your account and we'll send you a link to reset your password.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12 col-md-12 mb-5">
                <TextField
                  InputProps={{ sx: { borderRadius: "50px" } }}
                  label="Email*"
                  variant="outlined"
                  fullWidth
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 d-flex flex-column">
                <span className="text-danger fw-bold" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                <button
                  type="submit"
                  className="btn btn-custom-primary w-100 mb-3"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>)}
      </div>
    </div>
  );
}